body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@page {
    size: A4;
    margin: 0;
}
@media print {
    html, body {
        size: A4;
    }
    .quoteForPrint{
        margin: 10mm;
    }

    .quotePrintClientDetail{
        text-align:left;
    }


    /* ... the rest of the rules ... */
}

html, body {
    size: A4;
    margin: 0;
}
.quoteForPrint{
    margin: 10mm;
}

.quotePrintClientDetail{
    text-align:left;
    background:white;
    margin:0;
    padding:0;
}

.quotePrintClientDetail tbody tr{
    background:white;
    margin:0;
    padding:0;
}
.quotePrintClientDetail tbody tr td{
    border:0;
    margin:0;
    padding:0;
}

.quotePrintClientDetail tbody .label td{
    font-size:4mm;
    border-top: 2px solid #999;
    border-left:2px solid #999;
    border-right:2px solid #999;
    margin:0;
    padding:0;
}

.quotePrintClientDetail tbody .value td{
    font-size:6mm;
    border-bottom: 2px solid #999;
    border-left:2px solid #999;
    border-right:2px solid #999;
    margin:0;
    padding:0;
}

.subtotalLabel{
    color:blue;
    font-weight: bold;
    border-right:0;
    border-left-color:black;
}

.subtotalValue{
    border-left:0;
    color:blue;
    font-weight: bold;
}

.totalLabel{
    color: #af151c;
    font-weight: bold;
    border-right:0;
}

.totalValue{
    border-left:0;
    color: #af151c;
    font-weight: bold;
}

.finalBalance{
    text-align:right;
    font-size:8mm;
    font-weight:bold;
    color: #332eaf;
}
.authenticate{
    width:50%;

}

* {
    box-sizing: border-box;
}

input[type=text], select, textarea {
    width: 95%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}
.currency, .currency option {
    width: 10%;
    text-size:1em;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}


label {
    padding: 12px 5px 12px 0;
    display: inline-block;

}

input[type=submit] {
    background-color: #332eaf;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
}

input[type=submit]:hover {
    background-color: #332eaf;
}

.container, .NewSupplierForm {
    position:absolute;
    left:5%;
    border-radius: 5px;
    background-color: #c9c9c9;
    padding: 0 20px;
    width:80%;
    height:100%;
    top:1%;
    opacity:1;
    margin:auto;
    resize: both;
    overflow: auto;
    vertical-align:middle;
}

.formLabel{
    padding-left:0;
    margin:0;
    color:blue;
}



.col-25 {
    float: left;
    width: 25%;
    margin:0;
    padding:0;
}

.col-75 {
    float: left;
    left:0;
    width: 75%;
    margin:0;
    padding:0;
}

.col-75 input {
    text-align:left;
    float: left;
    width: 75%;
    margin:0;
    padding:0;
}

.row{
    padding:0;
    margin-top:20px;
}
/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.row-center{
    text-align:center;
    vertical-align:middle;
    padding:0;
    margin-top:20px;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
        width: 90%;
        margin: 0;
        padding:0;
    }
}

.center{
    text-align:center;
    bottom:10px;
    position:relative;
}

.buttonWrapper button {
    position:relative;
    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #332eaf;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
}

.buttonWrapper button:hover {
    background-color: #67a718
}

.buttonWrapper button:active {
    background-color: #332eaf;
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
}

.mostrar-dica{
    display:none;


}
.mostrar-dica p{
    margin:15px;
}

.dica{
    bottom:0;
    left:0;
    position:absolute;
    font-size:3em;
}
.dica:hover + .mostrar-dica, .mostrar-dica:hover {
    position:absolute;
    background: #ffdc81;

    z-index:20;
    width:50%;
    border-radius:20px;
    bottom:0;
    left:0;
    display:block;
}


/* The cancel button*/

.button-cancel {

    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #af151c;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
    margin-right:20px;
}

.button-cancel:hover {background-color: #af820f
}

.button-cancel:active {
    background-color: #af151c;
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
}

.updating{
    position:fixed;
    left:5%;
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 5px 20px;
    width:90%;
    height:90%;
    top:5%;
    opacity:0.9;
    margin:auto;
    resize: both;
    overflow: auto;
    vertical-align:middle;
}
img{
    position:absolute;
    max-width: 100%;
    max-height: 100%;
}

.blankRow{
    border-top-color:transparent;
    border-bottom-color:transparent;
    border-left-color:transparent;


}
.blankRow2{
    border-right-color:transparent;
    border-left-color:transparent;


}

div[class*="leftContent"] {
    text-align:left;
    z-index:1;

}
div[class*="leftContent__menu"] {


}

div[class*="leftContent__menu-list"] {

}

div[class*="leftContent__control"] {

}

div[class*="leftContent__value-container"] div {
    width:250%;
}

div[class*="leftContent__indicator"] {

}
div[class*="leftContent__dropdown-indicator"] {

}

div[class*="leftContent__placeholder"] {
    margin-left:10px;
}

div[class*="leftContent__input"] {
    margin-left:10px;
}

.loadingContainer{
    left:9.8cm;
    position:absolute;

}

.loadingLogo{
    position:absolute;
    left:18cm;
    top:9cm;
    width:21cm;
    -webkit-transform:scale(2);
    transform:scale(2);
}
.loadingInfo{
    position:absolute;
    text-align:center;
    top:2cm;
    width:21cm;

}

.failed{

    font: bold 15px Helvetica, Sans-Serif;
    text-transform: uppercase;
    position: absolute;
    top: 10%;
    left: 28.55%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 15px 30px;
    background: rgba(0,0,0,0.4);
    color: #fff;
    text-align: center;
    font-size: 22px;
    width: 21cm;

}

.quotationInfo{
    position:absolute;
    top:5cm;
    text-align:center;
    width:21cm;
    font: 0.75cm Helvetica, Sans-Serif;
}
.tabContent{
    padding:0;
    margin:0;
    border:0;

}
.sidenav {
    height: 100%;
    width: 12%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 6%;
    background-color: #111;
    overflow-x: hidden;
    padding-top: 20cm;
}

.sidenav a {
    padding: 6cm 6cm 6cm 1cm;
    text-decoration: none;
    font-size: 20cm;
    color: #818181;
    display: block;

}

.infoEnvio{
    font-size:0.75em;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.main {
    padding:0;
    margin:0 0 0 1%;
    border:0;
    width:85%;
    left:12%;
    text-align:left;
    position:absolute;
    opacity:0.9;


}

.openQuoteContainer{
    background-color:lightgrey;
    font-size:1.5em;
}

.visualizarCotacoes div .tabContent .sidenavContainer .main{

}

.openQuoteActions{

}

.actionContent{
    text-align:center;
    margin-top:0;
}


.actionContent button {
    margin: 15cm 20cm 20cm;
    padding: 4cm 10cm;
    font-size: 24cm;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #332eaf;
    border: none;
    border-radius: 15cm;
    box-shadow: 0 9cm #999;
}

.actionContent button:hover {
    background-color: #af820f
}

.actionContent button:active {
    background-color: #af151c;
    box-shadow: 0 5cm #666;
    -webkit-transform: translateY(4cm);
            transform: translateY(4cm);
}

.openQuoteContainer p{
    margin:0;
}

.enviarProdutosContainer{
    position:relative;
    left:30%;
    background:lightgrey;
    margin:10cm;
    width:40%;

}

.enviarProdutosContainer span{
    margin:10cm;
}

.enviarProdutosContainer .label{
    text-align:center;
}

input[type='number']{
    margin:20cm;
}

.enviarProdutosContainer span input{
    width:80%;
}

.inputValueNumber{
    margin:10cm;
    position:relative;
}

.inputElement .label{
    font-size:0.5em;
    margin-bottom:0;
}

.inputValue{
    margin:0 0 0 20cm;
}

.buttons {
    text-align:center;

}

.h4{
    text-align:center;
}

.quotationBuilderInstructions{
    text-align:left;
    margin-left:50cm;
    margin-right:50cm;
    padding:20cm;
    background:grey;
    opacity:0.9;
}

#quoteTitle{
    position:fixed;
    margin:5cm 0 0 20cm;
    z-index:5;
    font-size:1.5em;
}

#selectContainer{
    position:absolute;
    top:40cm;
    width:90%;
    margin-left:20cm;
    height:60cm;

}

#quoteDetailsContainer{
    position:absolute;
    top:80cm;
    width:100%;
}


.logoSquare{

}

.aerotubosLogo div,.aerotubosLogo div span{
    z-index:2;
    border-radius:2cm;
}

.firstBar{
    background:darkblue;
    position:absolute;
    left:0.6cm;
    top:4.4cm;
    height:0.4cm;
    width:1.4cm;
}
.secondBar{
    background:darkblue;
    position:absolute;
    left:3.1cm;
    top:4.4cm;
    height:0.4cm;
    width:5.3cm;
}

.thirdBar{
    background:darkblue;
    position:absolute;
    left:0.6cm;
    top:5.7cm;
    height:0.4cm;
    width:1.4cm;
}
.fourthBar{
    background:darkblue;
    position:absolute;
    left:3.1cm;
    top:5.7cm;
    height:0.4cm;
    width:5.3cm;
}

.aerotubosLogo div:nth-child(2){
    background:darkblue;
    position:absolute;
    left:3.3cm;
    top:0.9cm;
    height:0.4cm;
    width:2.5cm;
}
.aerotubosLogo div:nth-child(3){
    background:darkblue;
    position:absolute;
    left:4.0cm;
    top:1.6cm;
    height:0.4cm;
    width:2.5cm;
}
.aerotubosLogo div:nth-child(4){
    background:darkblue;
    position:absolute;
    left:4.7cm;
    top:2.3cm;
    height:0.4cm;
    width:2.5cm;
}
.aerotubosLogo div:nth-child(5){
    background:darkblue;
    position:absolute;
    left:5.5cm;
    top:3.0cm;
    height:0.4cm;
    width:2.5cm;
}
.aerotubosLogo div:nth-child(6){
    background:darkblue;
    position:absolute;
    left:5.3cm;
    top:3.7cm;
    height:0.4cm;
    width:2.9cm;
}

.aerotubosLogo div:nth-child(8){
    background:darkblue;
    position:absolute;
    left:0.4cm;
    top:5.0cm;
    height:0.4cm;
    width:8.9cm;
}



.aerotubosLogo div:nth-child(10){
    background: darkblue;
    position:absolute;
    left:5.3cm;
    top:6.4cm;
    height:0.4cm;
    width:2.9cm;
}

.aerotubosLogo div:nth-child(14){
    background: darkblue;
    position:absolute;
    left:3.3cm;
    top:9.1cm;
    height:0.4cm;
    width:2.5cm;
}


.aerotubosLogo div:nth-child(11){
    background: darkblue;
    position:absolute;
    left:5.5cm;
    top:7.1cm;
    height:0.4cm;
    width:2.5cm;
}

.aerotubosLogo div:nth-child(12){
    background: darkblue;
    position:absolute;
    left:4.7cm;
    top:7.7cm;
    height:0.4cm;
    width:2.5cm;
}

.aerotubosLogo div:nth-child(13){
    background: darkblue;
    position:absolute;
    left:4.0cm;
    top:8.4cm;
    height:0.4cm;
    width:2.5cm;
}

.aerotubosBackground{

    width:10.0cm;
    height:10.0cm;
    position:absolute;
    top:-1.6cm;
    left:-1.6cm;
    z-index:1; /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Safari */
    transform: rotate(-45deg);

}

.aerotubosBackground div:nth-child(1){
    position:absolute;
    background:white;
    top:1.0cm;
    width:10.0cm;
    height:0.1cm;
}

.aerotubosBackground div:nth-child(2){
    position:absolute;
    background:white;
    top:1.7cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(3){
    position:absolute;
    background:white;
    top:2.4cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(4){
    position:absolute;
    background:white;
    top:3.1cm;
    width:10.0cm;
    height:0.1cm;
}

.aerotubosBackground div:nth-child(5){
    position:absolute;
    background:white;
    top:3.8cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(6){
    position:absolute;
    background:white;
    top:4.5cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(7){
    position:absolute;
    background:white;
    top:5.1cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(8){
    position:absolute;
    background:white;
    top:5.8cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(9){
    position:absolute;
    background:white;
    top:6.5cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(10){
    position:absolute;
    background:white;
    top:7.2cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(11){
    position:absolute;
    background:white;
    top:7.9cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(12){
    position:absolute;
    background:white;
    top:8.6cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(13){

    position:absolute;
    background:white;
    top:9.2cm;
    width:10.0cm;
    height:1cm;

}

.logoContainer{
    position:absolute;
    -webkit-transform:scale(0.3);
    transform:scale(0.3);

}

#logoLetters{
    position:absolute;
    margin: 0 0 0 0;
    background: #7a7a7a;
    text-align: center;
    color: white;
    font: bold 1cm Helvetica, Sans-Serif;
    letter-spacing: 0.5cm;
    padding: 0.2cm 0;
    top:9.5cm;
    right:-10cm;
}
.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;

}
.lds-spinner div {
    -webkit-transform-origin: 32px 32px;
            transform-origin: 32px 32px;
    -webkit-animation: lds-spinner 1.2s linear infinite;
            animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 29px;
    width: 5px;
    height: 14px;
    border-radius: 20%;
    background: #000000;
}
.lds-spinner div:nth-child(1) {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
    -webkit-transform: rotate(150deg);
            transform: rotate(150deg);
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
    -webkit-transform: rotate(210deg);
            transform: rotate(210deg);
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
    -webkit-transform: rotate(240deg);
            transform: rotate(240deg);
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
    -webkit-transform: rotate(300deg);
            transform: rotate(300deg);
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
    -webkit-transform: rotate(330deg);
            transform: rotate(330deg);
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}
@-webkit-keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

#hiderow,
.delete {
  display: none;
}
@media {
    html, body {

        width: 21cm;
        height: 29.7cm;
    }

    /* ... the rest of the rules ... */
}
@page {
    size: A4;
    margin: 0;
    width: 21cm;
    height: 29.7cm;
}
@media print {
    html, body {

        -webkit-print-color-adjust: exact;
    }


    /* ... the rest of the rules ... */
}
body {
    -webkit-print-color-adjust: exact;

    margin: 0 0.5cm 0 0;
    width: 21cm;
    height: 29.7cm;
}

html{
    background-color:grey;

}
body{
    background-color:white;
}

* { margin: 0; padding: 0; }
body { font: 14px/1.4 Georgia, serif; margin: 0 0.5cm 0 0;}
#page-wrap {
    width: 21cm;
    margin: 0;
}

textarea { border: 0; font: 14px Georgia, Serif; overflow: hidden; resize: none; }
table { border-collapse: collapse; }
table td, table th { border: 1px solid black; padding: 5px; }

#header {
    position:relative;
    width: 100%;
    margin: 0 0 0 0;
    background: #222;
    text-align: center;
    color: white;
    font: bold 15px Helvetica, Sans-Serif;
    letter-spacing: 0.5cm;
    padding: 0.2cm 0;
}

#contactDetails{

}

#contactDetails tr td{
    border-color:transparent;
    height:0.5cm;
    margin:0;
    padding:0;
}


#address {
    height:4cm;
}
#customer {
    position:absolute;
    top:4.5cm;
}
#logoContainerOutter{
    position:absolute;
    top:25.8cm;
    left:18.1cm;
    z-index:10;

}
#bankData{
    text-transform: uppercase;
    font: 0.6cm Helvetica, Sans-Serif;
    position:absolute;
    top:24cm;
    height:4.2cm;
    left:1cm;
    border:1px solid;
}

#logo { text-align: right; float: right; position: relative; margin-top: 25px; border: 1px solid #fff; max-width: 540px; max-height: 100px; overflow: hidden; }
#logo:hover, #logo.edit { border: 1px solid #000; margin-top: 0px; max-height: 125px; }
#logoctr { display: none; }
#logo:hover #logoctr, #logo.edit #logoctr { display: block; text-align: right; line-height: 25px; background: #eee; padding: 0 5px; }
#logohelp { text-align: left; display: none; font-style: italic; padding: 10px 5px;}
#logohelp input { margin-bottom: 5px; }
.edit #logohelp { display: block; }
.edit #save-logo, .edit #cancel-logo { display: inline; }
.edit #image, #save-logo, #cancel-logo, .edit #change-logo, .edit #delete-logo { display: none; }
#customer-title {
    font-size: 20px;
    font-weight: bold;
    float: left;
    width:14cm;
    margin:0 0 0 0.5cm;

}

#meta {
    position:absolute;
    width:7cm;
    left:13.5cm;
}
#meta td { text-align: right;  }
#meta td.meta-head { text-align: left; background: #eee; }
#meta td textarea { width: 100%; height: 20px; text-align: right; }

#items {

    margin: 0.5cm 0 0 0.5cm;
    border: 1px solid black;
    position:absolute;
    top:7cm;
    width:20cm;
}
#items th { background: #eee; }
#items textarea { width: 80px; height: 50px; }
#items tr.item-row td { border: 0; vertical-align: top; }
#items td.description { width: 300px; }
#items td.item-name { width: 175px; }
#items td.description textarea, #items td.item-name textarea { width: 100%; }
#items td.total-line { border-right: 0; text-align: right; }
#items td.total-value { border-left: 0; padding: 10px; }
#items td.total-value textarea { height: 20px; background: none; }
#items td.balance { background: #eee; }
#items td.blank { border: 0; }

#terms {
    text-align: center;
    margin: 0 0 0 0;
    position:absolute;
    top:28.7cm;
    width:21cm;
    height:1cm;
    color: #8b1b1c;
}

#note{
    text-transform: uppercase;
    font: 0.6cm Helvetica, Sans-Serif;
    position:absolute;
    top:24cm;
    height:4.2cm;
    left: 9.5cm;
    border:1px solid;
    width:8.5cm;
}
#terms h5 {
    text-transform: uppercase;
    font: 0.4cm Helvetica, Sans-Serif;
    letter-spacing: 10px;
    border-bottom: 1px solid black;
    padding: 0 0 0 0; margin: 0 0 0 0; }
#terms div {
    width: 100%;
    text-align:
            center;}

textarea:hover, textarea:focus, #items td.total-value textarea:hover, #items td.total-value textarea:focus, .delete:hover { background-color:#EEFF88; }

.delete-wpr { position: relative; }
.delete { display: block; color: #000; text-decoration: none; position: absolute; background: #EEEEEE; font-weight: bold; padding: 0px 3px; border: 1px solid; top: -6px; left: -22px; font-family: Verdana; font-size: 12px; }
#identity{
    width:20.5cm;
    margin:0.5cm 0 0 0.5cm;
}

#moneyValue{
    float:right;
}


#customer-identification{
    font-size:0.75em;
    font-weight:normal;
}


@media {
    html, body {

        width: 21cm;
        height: 29.7cm;
    }

    /* ... the rest of the rules ... */
}
@page {
    size: A4;
    margin: 0;
    width: 21cm;
    height: 29.7cm;
}
@media print {
    html, body {

        -webkit-print-color-adjust: exact;
    }


    /* ... the rest of the rules ... */
}
body {
    -webkit-print-color-adjust: exact;

    margin: 0 0.5cm 0 0;
    width: 21cm;
    height: 29.7cm;
}

html{
    background-color:grey;

}
body{
    background-color:white;
}

* { margin: 0; padding: 0; }
body { font: 14px/1.4 Georgia, serif; margin: 0 0.5cm 0 0;}
#page-wrap {
    width: 21cm;
    margin: 0;
}

textarea { border: 0; font: 14px Georgia, Serif; overflow: hidden; resize: none; }
table { border-collapse: collapse; }
table td, table th { border: 1px solid black; padding: 5px; }

#header {
    position:relative;
    width: 100%;
    margin: 0 0 0 0;
    background: #222;
    text-align: center;
    color: white;
    font: bold 15px Helvetica, Sans-Serif;
    letter-spacing: 0.5cm;
    padding: 0.2cm 0;
}



#contactDetails tr td{

}


#address {
    height:4cm;
}
#customer {

}
#logoContainerOutter{
    position:absolute;
    top:0.65cm;
    left:1cm ;
    z-index:10;
}
#bankData{
    text-transform: uppercase;
    font: 0.6cm Helvetica, Sans-Serif;
    position:absolute;
    top:24cm;
    height:4.2cm;
    left:1cm;
    border:1px solid;
}

#logo { text-align: right; float: right; position: relative; margin-top: 25px; border: 1px solid #fff; max-width: 540px; max-height: 100px; overflow: hidden; }
#logo:hover, #logo.edit { border: 1px solid #000; margin-top: 0px; max-height: 125px; }
#logoctr { display: none; }
#logo:hover #logoctr, #logo.edit #logoctr { display: block; text-align: right; line-height: 25px; background: #eee; padding: 0 5px; }
#logohelp { text-align: left; display: none; font-style: italic; padding: 10px 5px;}
#logohelp input { margin-bottom: 5px; }
.edit #logohelp { display: block; }
.edit #save-logo, .edit #cancel-logo { display: inline; }
.edit #image, #save-logo, #cancel-logo, .edit #change-logo, .edit #delete-logo { display: none; }

#meta {
    position:absolute;
    width:7cm;
    left:13.5cm;
}
#meta td { text-align: right;  }
#meta td.meta-head { text-align: left; background: #eee; }
#meta td textarea { width: 100%; height: 20px; text-align: right; }

#items {

    margin: 0.5cm 0 0 0.5cm;
    border: 1px solid black;
    position:absolute;
    top:7.3cm;
    width:20cm;
}
#items th { background: #eee; }
#items textarea { width: 80px; height: 50px; }
#items tr.item-row td { border: 0; vertical-align: top; }
#items td.description { width: 300px; }
#items td.item-name { width: 175px; }
#items td.description textarea, #items td.item-name textarea { width: 100%; }
#items td.total-line { border-right: 0; text-align: right; }
#items td.total-value { border-left: 0; padding: 10px; }
#items td.total-value textarea { height: 20px; background: none; }
#items td.balance { background: #eee; }
#items td.blank { border: 0; }

#terms {
    text-align: center;
    margin: 0 0 0 0;
    position:absolute;
    top:28.7cm;
    width:21cm;
    height:1cm;
    color: #8b1b1c;
}

#note{
    text-transform: uppercase;
    font: 0.6cm Helvetica, Sans-Serif;
    position:absolute;
    top:24cm;
    height:4.2cm;
    left: 9.5cm;
    border:1px solid;
    width:8.5cm;
}
#terms h5 {
    text-transform: uppercase;
    font: 0.4cm Helvetica, Sans-Serif;
    letter-spacing: 10px;
    border-bottom: 1px solid black;
    padding: 0 0 0 0; margin: 0 0 0 0; }
#terms div {
    width: 100%;
    text-align:
            center;}

textarea:hover, textarea:focus, #items td.total-value textarea:hover, #items td.total-value textarea:focus, .delete:hover { background-color:#EEFF88; }

.delete-wpr { position: relative; }
.delete { display: block; color: #000; text-decoration: none; position: absolute; background: #EEEEEE; font-weight: bold; padding: 0px 3px; border: 1px solid; top: -6px; left: -22px; font-family: Verdana; font-size: 12px; }
#identity{
    width:20.5cm;
    margin:0.5cm 0 0 0.5cm;
}

#moneyValue{
    float:right;
}


#customer-identification{
    font-size:0.75em;
    font-weight:normal;
}


.papelTimbradoEndereco{
    border:none;
    position:absolute;
    width:12cm;
    left:9cm;
}
.papelTimbradoEndereco table tr td{
    border:none;
}
#papel-timbrado-customer-data{
    position:absolute;
    top:4.5cm;
    left:0.4cm;
}

#papel-timbrado-customer-data div table tr td{
    border:none;
}
