@page {
    size: A4;
    margin: 0;
}
@media print {
    html, body {
        size: A4;
    }
    .quoteForPrint{
        margin: 10mm;
    }

    .quotePrintClientDetail{
        text-align:left;
    }


    /* ... the rest of the rules ... */
}

html, body {
    size: A4;
    margin: 0;
}
.quoteForPrint{
    margin: 10mm;
}

.quotePrintClientDetail{
    text-align:left;
    background:white;
    margin:0;
    padding:0;
}

.quotePrintClientDetail tbody tr{
    background:white;
    margin:0;
    padding:0;
}
.quotePrintClientDetail tbody tr td{
    border:0;
    margin:0;
    padding:0;
}

.quotePrintClientDetail tbody .label td{
    font-size:4mm;
    border-top: 2px solid #999;
    border-left:2px solid #999;
    border-right:2px solid #999;
    margin:0;
    padding:0;
}

.quotePrintClientDetail tbody .value td{
    font-size:6mm;
    border-bottom: 2px solid #999;
    border-left:2px solid #999;
    border-right:2px solid #999;
    margin:0;
    padding:0;
}

.subtotalLabel{
    color:blue;
    font-weight: bold;
    border-right:0;
    border-left-color:black;
}

.subtotalValue{
    border-left:0;
    color:blue;
    font-weight: bold;
}

.totalLabel{
    color: #af151c;
    font-weight: bold;
    border-right:0;
}

.totalValue{
    border-left:0;
    color: #af151c;
    font-weight: bold;
}

.finalBalance{
    text-align:right;
    font-size:8mm;
    font-weight:bold;
    color: #332eaf;
}
.authenticate{
    width:50%;

}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

input[type=text], select, textarea {
    width: 95%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}
.currency, .currency option {
    width: 10%;
    text-size:1em;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}


label {
    padding: 12px 5px 12px 0;
    display: inline-block;

}

input[type=submit] {
    background-color: #332eaf;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
}

input[type=submit]:hover {
    background-color: #332eaf;
}

.container, .NewSupplierForm {
    position:absolute;
    left:5%;
    border-radius: 5px;
    background-color: #c9c9c9;
    padding: 0 20px;
    width:80%;
    height:100%;
    top:1%;
    opacity:1;
    margin:auto;
    resize: both;
    overflow: auto;
    vertical-align:middle;
}

.formLabel{
    padding-left:0;
    margin:0;
    color:blue;
}



.col-25 {
    float: left;
    width: 25%;
    margin:0;
    padding:0;
}

.col-75 {
    float: left;
    left:0;
    width: 75%;
    margin:0;
    padding:0;
}

.col-75 input {
    text-align:left;
    float: left;
    width: 75%;
    margin:0;
    padding:0;
}

.row{
    padding:0;
    margin-top:20px;
}
/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.row-center{
    text-align:center;
    vertical-align:middle;
    padding:0;
    margin-top:20px;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
        width: 90%;
        margin: 0;
        padding:0;
    }
}

.center{
    text-align:center;
    bottom:10px;
    position:relative;
}

.buttonWrapper button {
    position:relative;
    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #332eaf;
    border: none;
    border-radius: 15px;
    -webkit-box-shadow: 0 9px #999;
    box-shadow: 0 9px #999;
}

.buttonWrapper button:hover {
    background-color: #67a718
}

.buttonWrapper button:active {
    background-color: #332eaf;
    -webkit-box-shadow: 0 5px #666;
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
    -ms-transform: translateY(4px);
    transform: translateY(4px);
}

.mostrar-dica{
    display:none;


}
.mostrar-dica p{
    margin:15px;
}

.dica{
    bottom:0;
    left:0;
    position:absolute;
    font-size:3em;
}
.dica:hover + .mostrar-dica, .mostrar-dica:hover {
    position:absolute;
    background: #ffdc81;

    z-index:20;
    width:50%;
    border-radius:20px;
    bottom:0;
    left:0;
    display:block;
}


/* The cancel button*/

.button-cancel {

    bottom:0;
    padding: 12px 24px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #af151c;
    border: none;
    border-radius: 15px;
    -webkit-box-shadow: 0 9px #999;
    box-shadow: 0 9px #999;
    margin-right:20px;
}

.button-cancel:hover {background-color: #af820f
}

.button-cancel:active {
    background-color: #af151c;
    -webkit-box-shadow: 0 5px #666;
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
    -ms-transform: translateY(4px);
    transform: translateY(4px);
}

.updating{
    position:fixed;
    left:5%;
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 5px 20px;
    width:90%;
    height:90%;
    top:5%;
    opacity:0.9;
    margin:auto;
    resize: both;
    overflow: auto;
    vertical-align:middle;
}
img{
    position:absolute;
    max-width: 100%;
    max-height: 100%;
}

.blankRow{
    border-top-color:transparent;
    border-bottom-color:transparent;
    border-left-color:transparent;


}
.blankRow2{
    border-right-color:transparent;
    border-left-color:transparent;


}

div[class*="leftContent"] {
    text-align:left;
    z-index:1;

}
div[class*="leftContent__menu"] {


}

div[class*="leftContent__menu-list"] {

}

div[class*="leftContent__control"] {

}

div[class*="leftContent__value-container"] div {
    width:250%;
}

div[class*="leftContent__indicator"] {

}
div[class*="leftContent__dropdown-indicator"] {

}

div[class*="leftContent__placeholder"] {
    margin-left:10px;
}

div[class*="leftContent__input"] {
    margin-left:10px;
}

.loadingContainer{
    left:9.8cm;
    position:absolute;

}

.loadingLogo{
    position:absolute;
    left:18cm;
    top:9cm;
    width:21cm;
    -webkit-transform:scale(2);
    -moz-transform:scale(2);
    -ms-transform:scale(2);
    transform:scale(2);
}
.loadingInfo{
    position:absolute;
    text-align:center;
    top:2cm;
    width:21cm;

}

.failed{

    font: bold 15px Helvetica, Sans-Serif;
    text-transform: uppercase;
    position: absolute;
    top: 10%;
    left: 28.55%;
    transform: translate(-50%, -50%);
    padding: 15px 30px;
    background: rgba(0,0,0,0.4);
    color: #fff;
    text-align: center;
    font-size: 22px;
    width: 21cm;

}

.quotationInfo{
    position:absolute;
    top:5cm;
    text-align:center;
    width:21cm;
    font: 0.75cm Helvetica, Sans-Serif;
}