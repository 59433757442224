.tabContent{
    padding:0;
    margin:0;
    border:0;

}
.sidenav {
    height: 100%;
    width: 12%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 6%;
    background-color: #111;
    overflow-x: hidden;
    padding-top: 20cm;
}

.sidenav a {
    padding: 6cm 6cm 6cm 1cm;
    text-decoration: none;
    font-size: 20cm;
    color: #818181;
    display: block;

}

.infoEnvio{
    font-size:0.75em;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.main {
    padding:0;
    margin:0 0 0 1%;
    border:0;
    width:85%;
    left:12%;
    text-align:left;
    position:absolute;
    opacity:0.9;


}

.openQuoteContainer{
    background-color:lightgrey;
    font-size:1.5em;
}

.visualizarCotacoes div .tabContent .sidenavContainer .main{

}

.openQuoteActions{

}

.actionContent{
    text-align:center;
    margin-top:0;
}


.actionContent button {
    margin: 15cm 20cm 20cm;
    padding: 4cm 10cm;
    font-size: 24cm;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #332eaf;
    border: none;
    border-radius: 15cm;
    box-shadow: 0 9cm #999;
}

.actionContent button:hover {
    background-color: #af820f
}

.actionContent button:active {
    background-color: #af151c;
    box-shadow: 0 5cm #666;
    transform: translateY(4cm);
}

.openQuoteContainer p{
    margin:0;
}

.enviarProdutosContainer{
    position:relative;
    left:30%;
    background:lightgrey;
    margin:10cm;
    width:40%;

}

.enviarProdutosContainer span{
    margin:10cm;
}

.enviarProdutosContainer .label{
    text-align:center;
}

input[type='number']{
    margin:20cm;
}

.enviarProdutosContainer span input{
    width:80%;
}

.inputValueNumber{
    margin:10cm;
    position:relative;
}

.inputElement .label{
    font-size:0.5em;
    margin-bottom:0;
}

.inputValue{
    margin:0 0 0 20cm;
}

.buttons {
    text-align:center;

}

.h4{
    text-align:center;
}

.quotationBuilderInstructions{
    text-align:left;
    margin-left:50cm;
    margin-right:50cm;
    padding:20cm;
    background:grey;
    opacity:0.9;
}

#quoteTitle{
    position:fixed;
    margin:5cm 0 0 20cm;
    z-index:5;
    font-size:1.5em;
}

#selectContainer{
    position:absolute;
    top:40cm;
    width:90%;
    margin-left:20cm;
    height:60cm;

}

#quoteDetailsContainer{
    position:absolute;
    top:80cm;
    width:100%;
}


.logoSquare{

}

.aerotubosLogo div,.aerotubosLogo div span{
    z-index:2;
    border-radius:2cm;
}

.firstBar{
    background:darkblue;
    position:absolute;
    left:0.6cm;
    top:4.4cm;
    height:0.4cm;
    width:1.4cm;
}
.secondBar{
    background:darkblue;
    position:absolute;
    left:3.1cm;
    top:4.4cm;
    height:0.4cm;
    width:5.3cm;
}

.thirdBar{
    background:darkblue;
    position:absolute;
    left:0.6cm;
    top:5.7cm;
    height:0.4cm;
    width:1.4cm;
}
.fourthBar{
    background:darkblue;
    position:absolute;
    left:3.1cm;
    top:5.7cm;
    height:0.4cm;
    width:5.3cm;
}

.aerotubosLogo div:nth-child(2){
    background:darkblue;
    position:absolute;
    left:3.3cm;
    top:0.9cm;
    height:0.4cm;
    width:2.5cm;
}
.aerotubosLogo div:nth-child(3){
    background:darkblue;
    position:absolute;
    left:4.0cm;
    top:1.6cm;
    height:0.4cm;
    width:2.5cm;
}
.aerotubosLogo div:nth-child(4){
    background:darkblue;
    position:absolute;
    left:4.7cm;
    top:2.3cm;
    height:0.4cm;
    width:2.5cm;
}
.aerotubosLogo div:nth-child(5){
    background:darkblue;
    position:absolute;
    left:5.5cm;
    top:3.0cm;
    height:0.4cm;
    width:2.5cm;
}
.aerotubosLogo div:nth-child(6){
    background:darkblue;
    position:absolute;
    left:5.3cm;
    top:3.7cm;
    height:0.4cm;
    width:2.9cm;
}

.aerotubosLogo div:nth-child(8){
    background:darkblue;
    position:absolute;
    left:0.4cm;
    top:5.0cm;
    height:0.4cm;
    width:8.9cm;
}



.aerotubosLogo div:nth-child(10){
    background: darkblue;
    position:absolute;
    left:5.3cm;
    top:6.4cm;
    height:0.4cm;
    width:2.9cm;
}

.aerotubosLogo div:nth-child(14){
    background: darkblue;
    position:absolute;
    left:3.3cm;
    top:9.1cm;
    height:0.4cm;
    width:2.5cm;
}


.aerotubosLogo div:nth-child(11){
    background: darkblue;
    position:absolute;
    left:5.5cm;
    top:7.1cm;
    height:0.4cm;
    width:2.5cm;
}

.aerotubosLogo div:nth-child(12){
    background: darkblue;
    position:absolute;
    left:4.7cm;
    top:7.7cm;
    height:0.4cm;
    width:2.5cm;
}

.aerotubosLogo div:nth-child(13){
    background: darkblue;
    position:absolute;
    left:4.0cm;
    top:8.4cm;
    height:0.4cm;
    width:2.5cm;
}

.aerotubosBackground{

    width:10.0cm;
    height:10.0cm;
    position:absolute;
    top:-1.6cm;
    left:-1.6cm;
    z-index:1;
    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Safari */
    transform: rotate(-45deg);

}

.aerotubosBackground div:nth-child(1){
    position:absolute;
    background:white;
    top:1.0cm;
    width:10.0cm;
    height:0.1cm;
}

.aerotubosBackground div:nth-child(2){
    position:absolute;
    background:white;
    top:1.7cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(3){
    position:absolute;
    background:white;
    top:2.4cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(4){
    position:absolute;
    background:white;
    top:3.1cm;
    width:10.0cm;
    height:0.1cm;
}

.aerotubosBackground div:nth-child(5){
    position:absolute;
    background:white;
    top:3.8cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(6){
    position:absolute;
    background:white;
    top:4.5cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(7){
    position:absolute;
    background:white;
    top:5.1cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(8){
    position:absolute;
    background:white;
    top:5.8cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(9){
    position:absolute;
    background:white;
    top:6.5cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(10){
    position:absolute;
    background:white;
    top:7.2cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(11){
    position:absolute;
    background:white;
    top:7.9cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(12){
    position:absolute;
    background:white;
    top:8.6cm;
    width:10.0cm;
    height:0.1cm;
}
.aerotubosBackground div:nth-child(13){

    position:absolute;
    background:white;
    top:9.2cm;
    width:10.0cm;
    height:1cm;

}

.logoContainer{
    position:absolute;
    -webkit-transform:scale(0.3);
    -moz-transform:scale(0.3);
    -ms-transform:scale(0.3);
    transform:scale(0.3);

}

#logoLetters{
    position:absolute;
    margin: 0 0 0 0;
    background: #7a7a7a;
    text-align: center;
    color: white;
    font: bold 1cm Helvetica, Sans-Serif;
    letter-spacing: 0.5cm;
    padding: 0.2cm 0;
    top:9.5cm;
    right:-10cm;
}