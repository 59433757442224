@media {
    html, body {

        width: 21cm;
        height: 29.7cm;
    }

    /* ... the rest of the rules ... */
}
@page {
    size: A4;
    margin: 0;
    width: 21cm;
    height: 29.7cm;
}
@media print {
    html, body {

        -webkit-print-color-adjust: exact;
    }


    /* ... the rest of the rules ... */
}
body {
    -webkit-print-color-adjust: exact;

    margin: 0 0.5cm 0 0;
    width: 21cm;
    height: 29.7cm;
}

html{
    background-color:grey;

}
body{
    background-color:white;
}

* { margin: 0; padding: 0; }
body { font: 14px/1.4 Georgia, serif; margin: 0 0.5cm 0 0;}
#page-wrap {
    width: 21cm;
    margin: 0;
}

textarea { border: 0; font: 14px Georgia, Serif; overflow: hidden; resize: none; }
table { border-collapse: collapse; }
table td, table th { border: 1px solid black; padding: 5px; }

#header {
    position:relative;
    width: 100%;
    margin: 0 0 0 0;
    background: #222;
    text-align: center;
    color: white;
    font: bold 15px Helvetica, Sans-Serif;
    letter-spacing: 0.5cm;
    padding: 0.2cm 0;
}



#contactDetails tr td{

}


#address {
    height:4cm;
}
#customer {

}
#logoContainerOutter{
    position:absolute;
    top:0.65cm;
    left:1cm ;
    z-index:10;
}
#bankData{
    text-transform: uppercase;
    font: 0.6cm Helvetica, Sans-Serif;
    position:absolute;
    top:24cm;
    height:4.2cm;
    left:1cm;
    border:1px solid;
}

#logo { text-align: right; float: right; position: relative; margin-top: 25px; border: 1px solid #fff; max-width: 540px; max-height: 100px; overflow: hidden; }
#logo:hover, #logo.edit { border: 1px solid #000; margin-top: 0px; max-height: 125px; }
#logoctr { display: none; }
#logo:hover #logoctr, #logo.edit #logoctr { display: block; text-align: right; line-height: 25px; background: #eee; padding: 0 5px; }
#logohelp { text-align: left; display: none; font-style: italic; padding: 10px 5px;}
#logohelp input { margin-bottom: 5px; }
.edit #logohelp { display: block; }
.edit #save-logo, .edit #cancel-logo { display: inline; }
.edit #image, #save-logo, #cancel-logo, .edit #change-logo, .edit #delete-logo { display: none; }

#meta {
    position:absolute;
    width:7cm;
    left:13.5cm;
}
#meta td { text-align: right;  }
#meta td.meta-head { text-align: left; background: #eee; }
#meta td textarea { width: 100%; height: 20px; text-align: right; }

#items {

    margin: 0.5cm 0 0 0.5cm;
    border: 1px solid black;
    position:absolute;
    top:7.3cm;
    width:20cm;
}
#items th { background: #eee; }
#items textarea { width: 80px; height: 50px; }
#items tr.item-row td { border: 0; vertical-align: top; }
#items td.description { width: 300px; }
#items td.item-name { width: 175px; }
#items td.description textarea, #items td.item-name textarea { width: 100%; }
#items td.total-line { border-right: 0; text-align: right; }
#items td.total-value { border-left: 0; padding: 10px; }
#items td.total-value textarea { height: 20px; background: none; }
#items td.balance { background: #eee; }
#items td.blank { border: 0; }

#terms {
    text-align: center;
    margin: 0 0 0 0;
    position:absolute;
    top:28.7cm;
    width:21cm;
    height:1cm;
    color: #8b1b1c;
}

#note{
    text-transform: uppercase;
    font: 0.6cm Helvetica, Sans-Serif;
    position:absolute;
    top:24cm;
    height:4.2cm;
    left: 9.5cm;
    border:1px solid;
    width:8.5cm;
}
#terms h5 {
    text-transform: uppercase;
    font: 0.4cm Helvetica, Sans-Serif;
    letter-spacing: 10px;
    border-bottom: 1px solid black;
    padding: 0 0 0 0; margin: 0 0 0 0; }
#terms div {
    width: 100%;
    text-align:
            center;}

textarea:hover, textarea:focus, #items td.total-value textarea:hover, #items td.total-value textarea:focus, .delete:hover { background-color:#EEFF88; }

.delete-wpr { position: relative; }
.delete { display: block; color: #000; text-decoration: none; position: absolute; background: #EEEEEE; font-weight: bold; padding: 0px 3px; border: 1px solid; top: -6px; left: -22px; font-family: Verdana; font-size: 12px; }
#identity{
    width:20.5cm;
    margin:0.5cm 0 0 0.5cm;
}

#moneyValue{
    float:right;
}


#customer-identification{
    font-size:0.75em;
    font-weight:normal;
}


.papelTimbradoEndereco{
    border:none;
    position:absolute;
    width:12cm;
    left:9cm;
}
.papelTimbradoEndereco table tr td{
    border:none;
}
#papel-timbrado-customer-data{
    position:absolute;
    top:4.5cm;
    left:0.4cm;
}

#papel-timbrado-customer-data div table tr td{
    border:none;
}